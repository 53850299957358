.PropertyCard {
  margin-bottom: 1rem;
  color: inherit;
  border-radius: 10px;
  border: 1px solid rgb(199, 232, 186);
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
}

.PropertyCard img {
  object-fit: cover;
  height: 12rem;
  border-radius: 10px 10px 0 0;
}

.PropertyCard .badge {
  background-color: rgb(146, 180, 133);
}