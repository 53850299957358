.Navbar {
  background-color: white;
  border-bottom: 2px solid rgba(131, 197, 137, 0.547);
}

.navbar-brand {
  font-weight: bold;
  color: rgb(146, 180, 133);
}

.navbar-brand:hover, 
.navbar-brand:focus {
  color: rgb(146, 180, 133);
}

.navbar-nav .nav-link.active {
  font-weight: bold;
  color: rgb(101, 101, 101);
}